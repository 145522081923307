import propertyDataApplicationForm from './components/_property_data_application_form_component'
import propertyDataApplicationButton from './directives/_property_data_application_button_directive'

import bulkDataApplicationForm from './components/_bulk_data_application_form_component'
import dataexplorerSignupForm from './components/_dataexplorer_signup_form_component'

import propertyDataRequestPopup from './components/property_data_request_popup_component.js'
import bulkDataApplicationButton from './directives/_bulk_data_application_button_directive'
import dataexplorerSignupButton from './directives/_dataexplorer_signup_button_directive'

import dataApplicationModel from './models/_data_application_model'
import dataExplorerRegistrationModel from './models/_data_explorer_registration_model'

import trackDataApplications from './services/track_data_applications.js'

angular
  .module('bzDataApplicationModule', [])
  .component('propertyDataApplicationForm', propertyDataApplicationForm)
  .component('bulkDataApplicationForm', bulkDataApplicationForm)
  .component('dataexplorerSignupForm', dataexplorerSignupForm)
  .component('propertyDataRequestPopup', propertyDataRequestPopup)
  .directive('propertyDataApplicationButton', propertyDataApplicationButton)
  .directive('bulkDataApplicationButton', bulkDataApplicationButton)
  .directive('dataexplorerSignupButton', dataexplorerSignupButton)
  .factory('dataApplicationModel', dataApplicationModel)
  .factory('dataExplorerRegistrationModel', dataExplorerRegistrationModel)
  .factory('trackDataApplications', trackDataApplications)
