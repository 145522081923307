var ContractorPlanEdit = function () {
  this.init = function () {
    this.free_trial()
    this.cancel_plan()
  }

  this.free_trial = function () {
    $('#btn-trial').click(function (e) {
      e.preventDefault()
      $.ajax({
        url: '/api/v2/manage/admin/trials',
        type: 'POST',
        data: {
          contractor_id: $(this).attr('data-contractor-id'),
          plan_id: $(this).attr('data-plan-id'),
        },
        success: function (response, status, xhr) {
          $('#btn-trial').addClass('hide')
        },
      })
    })
  }

  this.cancel_plan = function () {
    //var plan_id ="0"
    $('.btn-cancel-plan').click(function (e) {
      var end_now = $('#end_plan_immediately').is(':checked')
      var url = '/api/v3/subscriptions/' + $(this).attr('data-subscription-id')

      if (end_now) {
        url += '?end_now=true'
      }

      e.preventDefault()
      $.ajax({
        url: url,
        type: 'PUT',
        success: function (response, status, xhr) {
          $('.btn-cancel-plan').addClass('hide')
          if (end_now) {
            $('.end-plan-immediately-wrapper').addClass('hide')
          }
        },
      })
    })
  }
}

$(function () {
  var contractor_plan_edit = new ContractorPlanEdit()
  contractor_plan_edit.init()
})

window.ContractorPlanEdit = ContractorPlanEdit
