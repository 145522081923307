/* global UserData */

import { map, cloneDeep } from 'lodash'
import template from './dataexplorer_signup_form.html'

const STATE_OPTIONS = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

const dataexplorerSignupForm = {
  bindings: {
    close: '&?',
  },
  template,
  controller: [
    'dataExplorerRegistrationModel',
    'emailPasswordFormService',
    function (dataExplorerRegistrationModel, emailPasswordFormService) {
      this.industryOptions = [
        'Real Estate',
        'Insurance',
        'Financial Services',
        'Other',
      ]
      this.baseFieldsList = [
        {
          element: 'input',
          label: 'Name',
          modelName: 'name',
          placeholder: 'John Smith',
          type: 'text',
          required: 'true',
        },
        {
          element: 'input',
          label: 'Business email address',
          modelName: 'email',
          placeholder: 'example@gmail.com',
          type: 'email',
          required: 'true',
        },
        {
          element: 'input',
          label: 'Password',
          modelName: 'password',
          placeholder: '********',
          type: 'password',
          required: 'true',
          getErrorMessage: (password) => {
            return emailPasswordFormService
              .validatePassword(password)
              .then(({ error }) => error)
          },
        },
        {
          element: 'input',
          label: 'Company',
          modelName: 'company',
          placeholder: 'My Company Name',
          type: 'text',
          required: 'true',
        },
        {
          element: 'select',
          label: 'Industry',
          modelName: 'industry',
          name: 'industry',
          options: this.industryOptions,
          required: 'true',
        },
        {
          element: 'input',
          label: 'Phone number',
          modelName: 'phone',
          placeholder: '(000) 000-0000',
          type: 'tel',
          required: 'true',
        },
        {
          element: 'select',
          label: 'State',
          modelName: 'state',
          type: 'dropdown',
          options: STATE_OPTIONS,
          required: 'true',
        },
        {
          element: 'textarea',
          label: 'Please give us a short description on how our data can help',
          modelName: 'description',
          placeholder: 'We would love to partner with you!',
          required: 'true',
        },
      ]

      if (UserData.user_signed_in() && !UserData.is_admin()) {
        this.baseFieldsList = this.baseFieldsList.filter(
          (field) =>
            !['name', 'email', 'password', 'phone'].includes(field.modelName)
        )
      }

      this.rolesByIndustry = {
        'Real Estate': [
          'Realtor',
          'Individual Investor',
          'Property Management',
        ],
        Insurance: ['Underwriting', 'Claims', 'Analytics', 'Innovation'],
        'Financial Services': [
          'Lender',
          'Hedge Fund',
          'Institutional Investor',
        ],
        Other: [
          'Contractor',
          'Homeowner',
          'Student',
          'Municipal/Government',
          'Inspector',
          'Appraiser',
          'Legal',
          'Data Broker',
          'Other',
        ],
      }

      this.header = 'Sign up for Data Explorer'
      this.subheader =
        'Please fill out the following details to begin your free trial of BuildZoom Data Explorer.'

      this.submitButtonText = 'Start Trial'

      this.$onInit = () => {
        this.currentFields = map(this.baseFieldsList, cloneDeep)
      }

      this.exit = () => {
        this.close()
      }

      this.getFieldIndexByLabel = (fieldLabel) =>
        this.currentFields.findIndex((field) => field.label == fieldLabel)

      this.handleUpdate = (fieldName, value) => {
        if (fieldName === 'industry' && value !== undefined) {
          let roleIndex = this.getFieldIndexByLabel('Role')
          if (roleIndex !== -1) {
            this.currentFields.splice(roleIndex, 1)
          }
          let roleOptions = this.rolesByIndustry[value]
          let roleSelect = {
            element: 'select',
            label: 'Role',
            modelName: 'role',
            name: 'role',
            options: roleOptions,
            required: 'true',
          }
          let industryIndex = this.getFieldIndexByLabel('Industry')
          this.currentFields.splice(industryIndex + 1, 0, roleSelect)
        }
      }

      this.submit = (form) => {
        return dataExplorerRegistrationModel.submit(form).then(() => {
          window.location.href = '/data-explorer'
        })
      }
    },
  ],
}

export default dataexplorerSignupForm
