const dataExplorerRegistrationModel = [
  '$http',
  'urlConstants',
  'utmService',
  function ($http, urlConstants, utmService) {
    return {
      apiPath: urlConstants.api.v1.path + 'data_explorer_registrations',

      submit: function (application) {
        return $http.post(this.apiPath, {
          application: { ...application },
          url: window.location.href,
          referral_source: 'data_explorer_signup',
          utm_source: utmService.getUtmSource(),
        })
      },
    }
  },
]
export default dataExplorerRegistrationModel
